import React, {useEffect} from 'react'

const Usuariosuno = ({solouno, sala}) => {

const [participantes, setParticipantes] = React.useState([]);

    const obtenerparticipantes = async () => {

      
    
        try {
    
          const response = await fetch(`${process.env.REACT_APP_BASE}/obtenerparticipantes/`, {
            method: 'POST', // Especifica el método POST
            headers: {
                'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
            },
            body: JSON.stringify({ sala }) // Convierte el objeto a una cadena JSON
        });
    
            if (!response.ok) {
                throw new Error('Error en la red');
            }
            const data = await response.json();
            if(data){
              if(data.message == "no hay"){
                setParticipantes([])
              }else
              {
                setParticipantes(data.usuarios)
              }
            //console.log(data);
            }else{
              //console.log("ERROR");
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        //console.log("SE EJECUTO")
        // if(activaintervalo){
        //   setActivaintervalo(false)
        // }else{
        //   setActivaintervalo(true)
        // }
    };

    const obtenerprimernombre = (displayName) => {
        var primerNombre = "";
     
          let nombre = displayName;
      
          for (let i = 0; i < nombre.length; i++) {
            if (nombre[i] === " ") {
              i = nombre.length;
            } else {
              primerNombre += nombre[i];
            }
          }
          return primerNombre;
        

    }

    const compararcon = (email) => {
        for (let i = 0; i < solouno.length; i++) {
        if (email === solouno[i]) {
            return true
        }
        }
    }

    useEffect(() => {obtenerparticipantes()}, []);

  return (
    <div>
      {participantes.map((val, key) => {
                  return (
                    <>
                    {compararcon(val.email) == true ? (<div key={key} className="flex flex-row flex-grow m-1">
                      <img
                     
                      className="w-6 h-6 cursor-pointer  rounded-full border-2 border-blue-500"
                      src={`${val.photo}`}
                      alt=""
                    />
                    <div className=" text-[12px] ml-2 mt-1 text-blue-600 font-bold">
                        {obtenerprimernombre(val.nombre)}
                      </div>
                  </div>) : (<></>)}
                    </>
                  );
                  })}
    </div>
  )
}

export default Usuariosuno
